import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './Pages/Header';
import Homepage from './Pages/Homepage';
import CAREERS from './Pages/WorkAtWelleo';
import WeAreWelleo from './Pages/WeAreWelleo';
import SignUp from './Pages/SignUp';
import ContactUs from './Pages/ContactUs';
import './App.css';
import { ref, getDownloadURL, uploadString } from 'firebase/storage';
import { Helmet } from 'react-helmet';
import { storage } from './Firebase/FirebaseConnection';
import { Link } from 'react-router-dom';
import ImageSlider from './ImageSlider';
import ImageSlider2 from './ImageSlider2';
import Footer from './Pages/Footer';
import Footer3 from './Pages/Footer3';
import MobileSlider from './Pages/MobileSlider';
import subscribeUser from './Mailchimp/MailchimpConnection';
import logo from "./Pages/images/welleo_logo_transp.png";


function OpenPDF() {
    window.open('/Privacy Policy.pdf', '_blank');
}
function App() {
    const [scrollCount, setScrollCount] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isUsingMouseWheel, setIsUsingMouseWheel] = useState(true);
    const [isVisibleHomepage, setIsVisibleHomepage] = useState(true);
    const location = useLocation();

    const maxScrollCount = isUsingMouseWheel ? 85 : 415;
    const [showBody1, setShowBody1] = useState(true);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const detectTouchpad = (e) => {
            if (e.wheelDeltaY && (e.wheelDeltaY === e.deltaY * -3)) {
                setIsUsingMouseWheel(false);
            } else {
                setIsUsingMouseWheel(true);
            }
        };

        const handleScroll = (event) => {
            const currentScroll = window.scrollY;
            if (scrollCount > (isUsingMouseWheel ? 83 : 113)) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };
        window.addEventListener('wheel', detectTouchpad, { passive: true });
        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            window.removeEventListener('wheel', detectTouchpad);
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isUsingMouseWheel, scrollCount, isVisible]);
    useEffect(() => {
        const detectTouchpad = (event) => {
            if (event.wheelDeltaY && (event.wheelDeltaY === event.deltaY * -3)) {
                setIsUsingMouseWheel(false);
            } else {
                setIsUsingMouseWheel(true);
            }
        };

        const handleScroll = (event) => {
            setTimeout(() => {
            }, 75);
        };

        console.log(maxScrollCount);
        window.addEventListener('wheel', detectTouchpad, { passive: true });
        window.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            window.removeEventListener('wheel', detectTouchpad);
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isUsingMouseWheel]);
    useEffect(() => {
        if (location.pathname !== "/") {
            setIsVisibleHomepage(false);
        } else {
            setIsVisibleHomepage(true);
        }
    }, [location.pathname]);
    useEffect(() => {
        const handleScroll = (event) => {
            const { deltaY } = event;

            setIsScrolling(true);
            setScrollCount(prevCount => {
                if (deltaY > 0 && prevCount < maxScrollCount) {
                    return prevCount + 1;
                } else {
                    return Math.max(prevCount - 1, 0);
                }
            });
        };

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [maxScrollCount]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 767.98) {
                setIsScrolling(false);
                setScrollCount(0);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [scrollCount]);

    useEffect(() => {
        console.log("isScrolling changed:", isScrolling);
    }, [isScrolling]);

    useEffect(() => {
        setIsScrolling(false);
        setScrollCount(0);
    }, [location.pathname]);

    return (
        <div className="App">
            <div className={`container ${location.pathname === '/Homepage' ? '' : 'height-fixed'}`}>
                <div className="overlay"></div>
                <div className="content">
                    <Header />
                    {isVisibleHomepage && (
                    <div className="HomePage">
                        <Helmet>
                            <link rel="canonical" href="https://staflyapp.com" />
                            <meta property="og:image" content={logo} />
                                <meta property="og:url" content="https://staflyapp.com" />
                            <meta name="twitter:image" content={logo} />
                        </Helmet>
                        <Body1 scrollCount={scrollCount} isUsingMouseWheel={isUsingMouseWheel} />
                        <Body2 isScrolling={isScrolling} scrollCount={scrollCount} isUsingMouseWheel={isUsingMouseWheel} />
                        <Body3 scrollCount={scrollCount} isUsingMouseWheel={isUsingMouseWheel} />
                        <Body4 scrollCount={scrollCount} isUsingMouseWheel={isUsingMouseWheel} />
                        <Body5 scrollCount={scrollCount} isUsingMouseWheel={isUsingMouseWheel} />
                        <div className="MobileSliderDiv">
                            <MobileSlider />
                        </div>
                        {isVisible && <Footer />}
                        <Footer3 />
                        </div>
                    )}
                    <Routes>
                        <Route path="/"  />
                        <Route path="/CAREERS" element={<CAREERS />} />
                        <Route path="/ABOUT US" element={<WeAreWelleo />} />
                        <Route path="/ContactUs" element={<ContactUs />} />
                        <Route path="/SignUp" element={<SignUp />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}


function Body1({ scrollCount, isUsingMouseWheel }) {
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const fadeStep = isUsingMouseWheel ? 1 / 10 : 5 / 50;
        if (scrollCount >= 0 && scrollCount <= (isUsingMouseWheel ? 12 : 50)) {
            setOpacity(1 - fadeStep * scrollCount);
        } else {
            setOpacity(0);
        }
    }, [scrollCount, isUsingMouseWheel]);

    return (
        <div className="Body1">
            <div className="div1-Body1" style={{ opacity }}>
                <div className="div1-content-Body2">
                    <h1 className="BodyH12">KEEP TRACK OF YOUR INTAKE</h1>
                    <p className="Body2P2">Track calories, micronutrients, and receive personalized
                        nutrition insights to optimize your health.</p>
                </div>
            </div>
        </div>
    );
}

function Body2({ scrollCount, isUsingMouseWheel }) {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const fadeStep = isUsingMouseWheel ? 1 / 10 : 5 / 50;

        if (scrollCount >= (isUsingMouseWheel ? 12 : 50) && scrollCount <= (isUsingMouseWheel ? 19 : 90)) {
            setOpacity(fadeStep * (scrollCount - (isUsingMouseWheel ? 11 : 50)));
        } else if (scrollCount > (isUsingMouseWheel ? 19 : 90) && scrollCount <= (isUsingMouseWheel ? 25 : 115)) {
            setOpacity(1);
        } else if (scrollCount > (isUsingMouseWheel ? 25 : 115) && scrollCount <= (isUsingMouseWheel ? 32 : 155)) {
            setOpacity(1 - fadeStep * (scrollCount - (isUsingMouseWheel ? 24 : 115)));
        } else {
            setOpacity(0);
        }
    }, [scrollCount, isUsingMouseWheel]);

    return (
        <div className="Body2">
            <div className="div1-Body2" style={{ opacity }}>
                <div className="div1-content-Body2">
                    <h1 className="BodyH12">BE ON TOP OF YOUR TRAINING GAME</h1>
                    <p className="Body2P2">Choose a personalized program or create your own,
                        track your workout data, improve strength, endurance, and monitor your progress in real-time.</p>
                </div>
            </div>
            <div className="div2-Body2">
                <ImageSlider2 />
            </div>
        </div>
    );
}


const Body3 = ({ scrollCount, isUsingMouseWheel }) => {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const fadeStep = isUsingMouseWheel ? 1 / 8 : 5 / 40;
        if (scrollCount >= (isUsingMouseWheel ? 33 : 155) && scrollCount <= (isUsingMouseWheel ? 40 : 195)) {
            setOpacity(fadeStep * (scrollCount - (isUsingMouseWheel ? 33 : 155)));
        } else if (scrollCount > (isUsingMouseWheel ? 40 : 195) && scrollCount <= (isUsingMouseWheel ? 45 : 220)) {
            setOpacity(1);
        } else if (scrollCount > (isUsingMouseWheel ? 45 : 220) && scrollCount <= (isUsingMouseWheel ? 53 : 260)) {
            setOpacity(1 - fadeStep * (scrollCount - (isUsingMouseWheel ? 45 : 220)));
        } else {
            setOpacity(0);
        }
    }, [scrollCount, isUsingMouseWheel]);

    return (
        <div className="Body2">
            <div className="div1-Body2" style={{ opacity }}>
                <div className="div1-content-Body2">
                    <h1 className="BodyH11">EASY TO STAY ON TRACK</h1>
                    <p className="Body2P2">Gain an overview of past achievements and upcoming tasks,
                        all conveniently in one place, making it effortless to reach your goals.</p>
                </div>
            </div>
            <div className="div2-Body2">
                {/*                <ImageSlider2 style={{ opacity: 0 }} />*/}
            </div>
        </div>
    );
};

const Body4 = ({ scrollCount, isUsingMouseWheel }) => {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const fadeStep = isUsingMouseWheel ? 1 / 8 : 5 / 40;

        if (scrollCount >= (isUsingMouseWheel ? 53 : 260) && scrollCount <= (isUsingMouseWheel ? 60 : 300)) {
            setOpacity(fadeStep * (scrollCount - (isUsingMouseWheel ? 52 : 260)));
        } else if (scrollCount > (isUsingMouseWheel ? 60 : 300) && scrollCount <= (isUsingMouseWheel ? 65 : 325)) {
            setOpacity(1);
        } else if (scrollCount > (isUsingMouseWheel ? 65 : 325) && scrollCount <= (isUsingMouseWheel ? 73 : 365)) {
            setOpacity(1 - fadeStep * (scrollCount - (isUsingMouseWheel ? 65 : 325)));
        } else {
            setOpacity(0);
        }
    }, [scrollCount, isUsingMouseWheel]);

    return (
        <div className="Body2">
            <div className="div1-Body2" style={{ opacity }}>
                <div className="div1-content-Body2">
                    <h1 className="BodyH11">CHART YOUR SUCCESS</h1>
                    <p className="Body2P2">Discover trends related to your goals and identify areas for improvement in your overall health profile to enhance well-being and performance!</p>
                </div>
            </div>
            <div className="div2-Body2" style={{ opacity }}>
                {/* Other content */}
            </div>
        </div>
    );
};

const Body5 = ({ scrollCount, isUsingMouseWheel }) => {
    const [opacity, setOpacity] = useState(0);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fadeStep = isUsingMouseWheel ? 1 / 8 : 5 / 40;

        if (scrollCount >= (isUsingMouseWheel ? 73 : 365) && scrollCount <= (isUsingMouseWheel ? 81 : 405)) {
            setOpacity(fadeStep * (scrollCount - (isUsingMouseWheel ? 73 : 365)));
        } else if (scrollCount > (isUsingMouseWheel ? 81 : 405) && scrollCount <= (isUsingMouseWheel ? 100 : 500)) {
            setOpacity(1);
        } else if (scrollCount > (isUsingMouseWheel ? 100 : 500) && scrollCount <= (isUsingMouseWheel ? 130 : 650)) {
            setOpacity(1 - fadeStep * (scrollCount - (isUsingMouseWheel ? 100 : 500)));
        } else {
            setOpacity(0);
        }
    }, [scrollCount]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Invalid email';
        }

        const domainRegex = /\.(com|se|ru|net|org|info|biz|gov|edu|mil|int|name|pro|aero|coop|museum|xyz|top|site|online|tech|store|art|blog|club|design|space|wiki|shop|us|uk|ca|de|fr|es|it|nl|au|ch|jp|cn|in)$/i;
        if (!domainRegex.test(email)) {
            return 'Invalid email';
        }

        return '';
    };

    const handleChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        const validationError = validateEmail(newEmail);
        setMessage(validationError);
        setMessageType(validationError ? 'error' : '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const validationError = validateEmail(email);
        if (validationError) {
            setMessage(validationError);
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        try {
            await subscribeUser(email, isChecked2, isChecked);
            setMessage('Email submitted successfully!');
            setMessageType('success');
        } catch (error) {
            setMessage(`Failed to submit email: ${error.message}`);
            setMessageType('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };

    return (
        <div className="HomePage2">
            <div className="overlay"></div>
            <div className="SignUpBody" style={{ opacity }}>
                <div className="Content1">
                    <h1 className="H12">CONTROL YOUR HEALTH AND PERFORMANCE</h1>
                    <p className="SignUpP">We will notify you when Stafly is available.</p>
                    <form onSubmit={handleSubmit} className="InputButton">
                        <input
                            type="email"
                            placeholder="Enter Email"
                            className="Input-Body2"
                            value={email}
                            onChange={handleChange}
                            required
                        />
                        <div className="CheckBoxDiv">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="newsletter"
                                value="subscribe"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label className="checkboxText" htmlFor="checkbox">
                                Send me regular health and fitness updates
                            </label>
                        </div>
                        <div className="CheckBoxDiv">
                            <input
                                type="checkbox"
                                id="checkbox2"
                                name="newsletter"
                                value="subscribe"
                                checked={isChecked2}
                                onChange={handleCheckboxChange2}
                            />
                            <label className="checkboxText" htmlFor="checkbox2">
                                By signing up, you confirm that, you are 13 years old or older, have read and agree with the <span className="colored-text" onClick={() => OpenPDF()}>Privacy Policy</span>
                            </label>
                        </div>
                        <button type="submit" className="SignUpButton buttonSign" disabled={!isChecked2 || isLoading}>
                            {isLoading ? 'Processing...' : 'SIGN UP'}
                        </button>
                        <div className="ResponseMessage">
                            {message && <p className={`message ${messageType}`}>{message}</p>}
                        </div>
                    </form>

                </div>
                <div className="Content2">
                    <img src={require('./Web_Shuffle2/0080.png')} alt="Welleo120" className="Image" style={{ opacity: 0 }} />
                </div>
                {/*{isLoading && <div className="loading-spinner"></div>} */}
            </div>
            {/*<Footer2/>*/}
        </div>
    );
};

export default App;
