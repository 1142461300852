import './WorkAtWelleo.css';
import React from 'react';
import joinUsImage from './images/join_us_image.png';
import codePicture from './images/CodePicture.png';
import internshipImage from './images/job_posting_header_internship.png';
import { Helmet } from 'react-helmet';
import frontendImage from './images/job_posting_header_frontend.png';
import managerImage from './images/job_posting_header_projectman.png';
import workload50100 from './images/50-100.png';
import workload2550 from './images/25-50.png';
import Footer from './Footer2';
import logo from "./images/welleo_logo_transp.png";
function WorkAtWelleo() {
    return (
        <div className="WorkAtWelleo">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href="https://staflyapp.com/CAREERS" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://staflyapp.com/CAREERS" />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <Body1 />
            <Footer />
        </div>
    );
}
function Body1() {
    const backendLink = 'https://docs.google.com/forms/d/1k54FhBNQvk776lkAb-8oxSEQPZFbuP4fdBgVuLasPQY';
    const frontendLink = 'https://docs.google.com/forms/d/1k54FhBNQvk776lkAb-8oxSEQPZFbuP4fdBgVuLasPQY';
    const internshipLink = 'https://docs.google.com/forms/d/1RYHPN4HAr6JSvXehzycVVVXT5uD02RsxQUt3GXHT1DE';
    const technichalLeadLink = 'https://docs.google.com/forms/d/1HLqNcBqncJeNWoz6s-2JnWENT02YjjlyXps45tvwvQ4';
    return (
        <div className="WorkAtWelleoBody">
            <div className="FirstContainer">
                <div className="WAW-grid-container1">
                    <div className="WAW-grid-item">
                        <div className="WAW-grid-item-text">
                            <h3 className="BolderH3"><span className="colored-text3">WE ARE HIRING</span></h3>
                            <p className="BolderP">We are currently developing our platform and app and are looking for both junior and experienced talent. Check out our open positions below and apply today!</p>
                        </div>
                        <div className="WAW-grid-item-image">
                            <img className="JoinUs" src={joinUsImage} alt="Join us" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="OpenPositionsDiv">
                <h2 className="OpenPositions"><span className="colored-text2">OPEN POSITIONS</span></h2>
            </div>
            <div className="SecondContainer">
                <JobListing
                    title="C# & .NET DEVELOPER (INTERNSHIP)"
                    backgroundImage={internshipImage}
                    skills={[
                        "Driven and goal-oriented",
                        "Fluent in Swedish and English",
                    ]}
                    meritorious={[
                        "Interested in health & fitness",
                        "Experience with C# or .NET MAUI"
                    ]}
                    responsibilities={[
                        "Back- and/or Frontend development",
                        "Database integration",
                        "Working with authentication",
                        "Algorithm development",
                    ]}
                    workloadImage={workload50100}
                    link={internshipLink}
                />
                <JobListing
                    title="FRONTEND DEVELOPER"
                    backgroundImage={frontendImage}
                    skills={[
                        "Experience in .NET MAUI",
                        "Creatively inclined",
                        "Driven and goal-oriented",
                        "A self-starter",
                    ]}
                    meritorious={[
                        "Interested in health & fitness",
                        "Experience with C#"
                    ]}
                    responsibilities={[
                        "Frontend in .NET MAUI",
                        "Implement FIGMA mockups",
                        "XAML development",
                        "Some backend development",
                        "UI/UX Design",
                    ]}
                    workloadImage={workload2550}
                    link={frontendLink}
                />
                <JobListing
                    title="BACKEND DEVELOPER"
                    backgroundImage={codePicture}
                    skills={[
                        "C# experience",
                        "Driven and goal-oriented",
                        "Excellent analytical skills",
                    ]}
                    meritorious={[
                        "Interested in health & fitness",
                        "Experience with .NET MAUI"
                    ]}
                    responsibilities={[
                        "Backend development",
                        "Database integration",
                        "Working with APIs",
                        "Algorithm development",
                        "C# development",
                    ]}
                    workloadImage={workload2550}
                    link={backendLink}
                />
                <JobListing
                    title="TECHNICAL PROJECT MANAGER"
                    backgroundImage={managerImage}
                    skills={[
                        "Project management",
                        "Excellent analytical skills",
                        "Good communication skills",
                    ]}
                    meritorious={[
                        "Interested in health & fitness",
                        "Experience with C# and .NET MAUI"
                    ]}
                    responsibilities={[
                        "Further develop the project",
                        "Be an active part of the development process",
                        "Manage and optimize resources",
                        "Reviewing resource needs",
                    ]}
                    workloadImage={workload2550}
                    link={technichalLeadLink}
                />
            </div>
        </div>
    );
}

function JobListing({ title, backgroundImage, skills, meritorious, responsibilities, workloadImage, link }) {
    return (
        <div className="WAW-grid-container2">
            <div className="WAW-grid-container2-item1" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="WAW-grid-container2-item1-overlay">
                    <h2 className="DevH2">
                        <span className="colored-text2">{title.split(' ')[0]}</span> <span className="white-text2">{title.split(' ').slice(1).join(' ')}</span>
                    </h2>
                </div>
            </div>
            <div className="WAW-grid-container2-item2">
                <img className="WorkLoadImages" src={workloadImage} alt="Workload" />
            </div>

            <div className="WAW-grid-container2-item3">
                <ul>
                    <h5>PREFERRED SKILLS</h5>
                    {skills.map(skill => (
                        <li key={skill}>{skill}</li>
                    ))}
                    <h5>MERITORIOUS</h5>
                    {meritorious.map(item => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div>
            <div className="WAW-grid-container2-item4">
                <ul>
                    <h4>RESPONSIBILITIES</h4>
                    {responsibilities.map(responsibility => (
                        <li key={responsibility}>{responsibility}</li>
                    ))}
                </ul>
            </div>
            <button className="ApplHereButton" onClick={() => window.location.href = link}>READ MORE</button>
        </div>
    );
}

export default WorkAtWelleo;